<template>
  <Confirm
    :dialog="dialog"
    :hide-cancel="true"
    :hide-close="isOnDependents"
    :hide-confirm="true"
    :persistent="isOnDependents"
    :title="`Important Notice: Migration to New Dependant Functionality`"
    cancelText="Close"
    confirmText="Select"
    max-width="90%"
    rootClass="templates-dialog"
    width="560px"
    @confirm="onConfirm"
    @toggleDialog="onCancel"
  >
    <div class="dependents-migration">
      <h3>
        <p>Dear {{ displayName || "" }},</p>
      </h3>

      <p>
        We're excited to introduce our new Dependant functionality, designed to enhance your experience. As part of this
        upgrade, we need your assistance to ensure a smooth migration.
      </p>
      <p class="pb-3">
        You currently have dependents listed in our legacy system. We kindly ask you to provide the following required
        information to complete the migration process:
      </p>

      <div class="root-list">
        <v-card v-for="item in oldDependentsNotMigrated" :key="item.id">
          <v-container>
            <v-row>
              <v-col class="d-flex align-center justify-md-space-between" cols="12">
                <span class="item-title">
                  {{ item.firstName || "" }}
                  {{ item.lastName || "" }}
                </span>
                <PrimaryButton text="Complete" @onClick="onComplete(item)" />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
      <p class="pt-3">
        Completing this information will help us maintain the accuracy of your account and ensure a seamless transition.
        Rest assured, your data's security and privacy remain our utmost priority. Should you encounter any issues or
        require assistance, our support team is here to help
        <a href="tel:1-(844)-984-2947" target="_blank">1-(844)-984-2947</a>.
      </p>
      <p>Thank you for your cooperation as we work towards providing you with an even better user experience.</p>
    </div>
  </Confirm>
</template>
<script>
import { mapActions, mapState as mapPiniaState } from "pinia";
import { mapState } from "pinia";

import { DependentsApi } from "@/api/dependents";
import Confirm from "@/components/shared/Confirm.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { useDependentsStore } from "@/pinia-store/dependents";
import { routesEnum } from "@/types/Routes.enum";

export default {
  name: "DependentsMigrationInfo",
  components: { PrimaryButton, Confirm },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapPiniaState(useAuthStore, ["uid", "displayName"]),
    ...mapState(useDependentsStore, ["notMigratedCount", "oldDependents"]),
    isOnDependents() {
      return this.$route.name === routesEnum.patientDependents;
    },
    oldDependentsNotMigrated() {
      return this.oldDependents.filter((item) => !(item.migrated === true));
    },
  },
  watch: {
    $route(route) {
      if (route.name === routesEnum.patientDependents && this.notMigratedCount > 0 && !this.dialog) {
        this.dialog = true;
      }
    },
  },
  methods: {
    ...mapActions(useDependentsStore, ["setOldDependents"]),
    async onConfirm() {
      this.dialog = false;
    },
    onComplete(item) {
      this.$router.push({ name: routesEnum.patientDependentsRegister, query: { ...item } });
      this.dialog = false;
    },
    onCancel() {
      if (this.$route.name === routesEnum.patientDependents && this.notMigratedCount > 0) {
        return;
      }
      this.dialog = false;
    },
  },
  async mounted() {
    const dependents = await DependentsApi.getByInviterId(this.uid);
    this.setOldDependents(dependents);
    this.dialog = Boolean(this.notMigratedCount > 0);
  },
};
</script>
<style lang="scss">
.dependents-migration {
  .root-list {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  .item-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    color: #33343e;
  }
}
</style>
