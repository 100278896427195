import { defineStore } from "pinia";

interface DependentsState {
  oldDependents: Record<any, any>[];
}

export const useDependentsStore = defineStore({
  id: "dependents",
  state: (): DependentsState => ({
    oldDependents: [],
  }),
  actions: {
    setDependents(payload: any) {
      this.oldDependents = payload;
    },
    setOldDependents(payload: any) {
      this.oldDependents = payload;
    },
  },
  getters: {
    notMigratedCount: (state) => state.oldDependents.filter((dependents) => !(dependents?.migrated === true)).length,
  },
});
