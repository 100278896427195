<template>
  <Layout :navTabs="navTabs" :quickActions="quickActions">
    <keep-alive include="patientChat">
      <router-view></router-view>
    </keep-alive>
    <VisitReminder />
    <MeetingReminder />
    <QuickMonitoringModal :dialog="formKindSelector" @close="toggleFormKindSelector" />
    <AddBloodPressure />
    <AddBloodGlucose />
    <AddBodyTemperature />
    <AddHeartRate />
    <AddOxygenSaturation />
    <AddRespiratoryRate />
    <DependentsMigrationInfo />
  </Layout>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { PatientsApi } from "@/api/patients";
import chat from "@/assets/sounds/chat.mp3";
import Layout from "@/components/Layout";
import MeetingReminder from "@/components/VideoRoom/MeetingReminder";
import VisitReminder from "@/components/VisitReminder";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { WS_MEETING_ROOMS_ADDED, WS_NOTIFICATION } from "@/eventBuses/socketEvents";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useChatStore } from "@/pinia-store/chat";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";
import { useMonitoringStore } from "@/pinia-store/monitoring";
import { useNotificationsStore } from "@/pinia-store/notifications";
import { useObservationsStore } from "@/pinia-store/observations";
import { usePatientStore } from "@/pinia-store/patient";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { layoutPatientNavTabs, layoutPatientQuickActions } from "@/router/utils";
import { NotificationTypes } from "@/types/notificationTypes";
import { RolesEnum } from "@/types/Roles.enum";
import { audioVolume } from "@/utils/constants";
import DependentsMigrationInfo from "@/views/Patient/DependentsMigrationV1/DependentsMigrationInfo.vue";
import AddBloodGlucose from "@/views/Patient/Monitoring/bloodGlucose/Add";
import AddBloodPressure from "@/views/Patient/Monitoring/bloodPressure/Add";
import AddBodyTemperature from "@/views/Patient/Monitoring/bodyTemperature/Add";
import AddHeartRate from "@/views/Patient/Monitoring/heartRate/Add";
import AddOxygenSaturation from "@/views/Patient/Monitoring/oxygenSaturation/Add";
import QuickMonitoringModal from "@/views/Patient/Monitoring/QuickMonitoringModal";
import AddRespiratoryRate from "@/views/Patient/Monitoring/respiratoryRate/Add";

export default {
  name: "patientView",
  inject: ["injectable"],
  data() {
    return {
      clinics: [],
      timer: null,

      address: "",
      audioPlayer: new Audio(chat),
      audioTimeout: null,
      unconsumedMessageCount: 0,
      settings: {},
      items: [
        {
          title: "Click Me",
        },
      ],
    };
  },
  components: {
    DependentsMigrationInfo,
    MeetingReminder,
    AddRespiratoryRate,
    AddOxygenSaturation,
    AddHeartRate,
    AddBodyTemperature,
    AddBloodGlucose,
    AddBloodPressure,
    QuickMonitoringModal,
    Layout,
    VisitReminder,
  },
  computed: {
    ...mapState(usePatientStore, ["proxyAccounts"]),
    ...mapState(useAuthStore, ["uid", "userIsPatient", "isDependent"]),
    ...mapState(useObservationsStore, ["formKindSelector"]),
    ...mapState(useAppointmentsStore, ["patientNavbarAppointmentsCount"]),
    navTabs() {
      return this.isDependent
        ? layoutPatientNavTabs(this.$i18n).filter((i) => i.id !== "dependents")
        : layoutPatientNavTabs(this.$i18n);
    },
    quickActions() {
      return layoutPatientQuickActions(this.$i18n);
    },
  },
  methods: {
    ...mapActions(usePatientStore, ["getPatientProxyAccounts"]),
    ...mapActions(useAppointmentsStore, ["getPendingAppointments"]),
    ...mapActions(useProfileSettingsStore, ["getPatientSettings"]),
    ...mapActions(useNotificationsStore, ["getUserNotifications", "getNotifications", "addNotification"]),
    ...mapActions(useChatStore, ["setUnreadChannels"]),
    ...mapActions(useObservationsStore, ["toggleFormKindSelector"]),
    ...mapActions(useMeetingRoomStore, ["addMeetingRoom"]),
    ...mapActions(useMonitoringStore, ["getOrders"]),
  },
  beforeDestroy() {
    this.sockets.unsubscribe(WS_NOTIFICATION);
    this.sockets.unsubscribe("reminder");
    this.sockets.unsubscribe(WS_MEETING_ROOMS_ADDED);
    clearInterval(this.timer);
  },
  async mounted() {
    await this.getNotifications();
    this.sockets.subscribe(WS_NOTIFICATION, (item) => {
      if (item?.kind === NotificationTypes.APPOINTMENT_ADDED && item.content) {
        snackBarEventBus.$emit(snackBarEventName, { message: item.content, type: "success" });
      }
      this.audioPlayer.play();
      this.getNotifications();
    });
    this.sockets.subscribe(WS_MEETING_ROOMS_ADDED, (data) => {
      this.addMeetingRoom(data);
    });

    this.injectable.twilioContext.unconsumedMessagesDeltaSum.subscribe(async (val) => {
      if (this.$router.currentRoute.path !== "/patient/chat" && val > 0 && this.unconsumedMessageCount !== val) {
        try {
          if (this.audioTimeout) clearTimeout(this.audioTimeout);
          this.audioTimeout = setTimeout(() => {
            this.audioPlayer.volume = audioVolume;
            this.audioPlayer.play();
          }, 500);
        } catch (e) {
          prompt(e);
        }
      }
      this.unconsumedMessageCount = val;
    });
    this.settings = await PatientsApi.getSettings(this.uid);
    await this.getPatientSettings();
    await Promise.all([
      this.getPendingAppointments({ status: "pending", patientId: this.uid, includeDependents: true }),
      this.getPatientProxyAccounts(),
      this.getNotifications(),
      this.getOrders({
        patientId: this.uid,
        active: true,
      }),
    ]);
    this.timer = setInterval(() => {
      // this will remove start end date from search
      this.getPendingAppointments({ status: "pending", patientId: this.uid, includeDependents: true });
    }, 3000);
    if (this.patientNavbarAppointmentsCount > 0) this.audioPlayer.play();
    if (this.proxyAccounts?.length)
      await Promise.all(
        this.proxyAccounts.map(($item) =>
          this.getUserNotifications({
            userId: $item.id,
            notSeenContent: true,
          }),
        ),
      );
    this.$socket.emit("connected", { uid: this.uid, role: RolesEnum.Patient });
  },
};
</script>
